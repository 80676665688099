import { signOut } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { auth } from "../../../firebase/firebaseConfig";
import { icons } from "../../../utils/icons";

interface HeaderProps {
    onSidebarToggle: (isOpen: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ onSidebarToggle }) => {
    const notificationList = [
        { from: 'KLIA 1', time: '3 min ago', title: 'Flight Departure Change', badge: 'New' },
        { from: 'SUBANG', time: '1 hour ago', title: 'Flight cancel due bad weather', badge: 'Unread' }
    ];

    const airportLocation = ['KUL', 'PEN', 'BKI', 'KBR', 'PKU', 'KCH']

    const [isOpen, setIsOpen] = useState(true);
    const [isDropdownOpen, setProfileOpen] = useState(false);
    const [isLocationOpen, setIsLocationOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [isUTC, setIsUTC] = useState(true);

    const notificationRef = useRef<HTMLDivElement>(null);
    const locationRef = useRef<HTMLDivElement>(null);
    const profileRef = useRef<HTMLDivElement>(null);

    const toggleSidebar = () => {
        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
        onSidebarToggle(newIsOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;

            // Close notification dropdown if clicked outside
            if (notificationRef.current && !notificationRef.current.contains(target)) {
                setIsNotificationOpen(false);
            }

            if (locationRef.current && !locationRef.current.contains(target)) {
                setIsLocationOpen(false);
            }

            // Close profile dropdown if clicked outside
            if (profileRef.current && !profileRef.current.contains(target)) {
                setProfileOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    return (
        <header className="w-full">
            <nav className="top-0 z-50 dark:bg-gray-800 dark:border-gray-700">
                <div className="px-3 py-2 lg:px-5 lg:pl-3 flex flex-wrap items-center gap-x-2 justify-between">
                    {/* Logo and Sidebar Toggle */}
                    <div className="flex items-center w-full lg:w-auto">
                        <a href="/dashboard" className="flex ms-2 md:me-24">
                            <img src={require('../../../assets/logo-mahb.png')} className="h-8 me-3" alt="FlowBite Logo" />
                        </a>
                        {/* Always visible toggle button */}
                        <button
                            onClick={toggleSidebar}
                            type="button"
                            className="inline-flex items-center p-2 text-sm text-blue-600 rounded-lg bg-blue-100 hover:bg-blue-400 hover:text-white"
                        >
                            {isOpen ? <icons.RI.RiMenuUnfold4Line size={24} /> : <icons.RI.RiMenuFold4Line size={24} />}
                        </button>
                    </div>

                    {/* Search Bar */}
                    <div className="flex-grow flex justify-between items-center mt-2 lg:mt-0">
                        <form className="w-full max-w-xs lg:w-96">
                            <div className="relative">
                                <input
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full ps-10 pe-20 p-2.5"
                                    placeholder="Search"
                                />
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <icons.PI.PiMagnifyingGlass />
                                </div>
                                <button type="button" className="absolute m-1 inset-y-0 end-0 flex items-center px-2 text-sm text-blue-600 rounded-lg bg-blue-200 hover:bg-blue-400 hover:text-white">
                                    <icons.PI.PiFadersHorizontalBold size={20} />
                                </button>
                            </div>
                        </form>

                        {/* Notification and Profile */}
                        <div className="flex w-fit items-center justify-between gap-x-3">
                            {/* Airport button */}
                            <div>
                                <button
                                    type="button"
                                    onClick={() => setIsLocationOpen((prev) => !prev)}
                                    className="relative inline-flex items-center p-1 text-sm font-medium text-center text-green-600 hover:text-white bg-blue-100 rounded-full hover:bg-blue-400">
                                    <icons.PI.PiGlobeHemisphereWestDuotone size={24} />
                                </button>

                                {/* Airport dropdown */}
                                {isLocationOpen && (
                                    <div ref={locationRef} className="absolute w-32 mt-1 bg-white divide-y divide-gray-100 rounded shadow-2xl right-44 top-15 z-50">
                                        <div className="px-4 py-3">
                                            <p className="text-sm font-medium text-gray-900 dark:text-white">Airports</p>
                                        </div>
                                        <ul className="p-2">
                                            {airportLocation.map((loc, index) => (
                                                <li className="" key={index}>
                                                    <div className="flex items-center">
                                                        <input id="checkbox-2" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                        <label htmlFor="checkbox-2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{loc}</label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>

                            {/* Notification button */}
                            <div>
                                <button
                                    type="button"
                                    onClick={() => setIsNotificationOpen((prev) => !prev)}
                                    className="relative inline-flex items-center p-2 text-sm font-medium text-center text-blue-600 hover:text-white bg-blue-100 rounded-lg hover:bg-blue-400">
                                    <icons.PI.PiBellRinging size={24} />
                                    <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">8</div>
                                </button>

                                {/* Notification dropdown */}
                                {isNotificationOpen && (
                                    <div ref={notificationRef} className="absolute w-72 mt-1 bg-white divide-y divide-gray-100 rounded shadow-2xl right-24 top-15 z-50">
                                        <div className="px-4 py-3">
                                            <p className="text-sm font-medium text-gray-900 dark:text-white">All Notification</p>
                                        </div>
                                        <ul className="py-1">
                                            {notificationList.map((noty, index) => (
                                                <li className="p-2 border-b border-gray-100" key={index}>
                                                    <button className="w-full rounded-lg px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                        <div className="p-2">
                                                            <div className="flex justify-between w-full">
                                                                <p className="font-semibold text-sm">{noty.from}</p>
                                                                <p className={`${index === 1 ? 'bg-blue-100 text-blue-800' : 'bg-orange-100 text-orange-800'} text-xs font-medium me-2 px-2.5 py-0.5 rounded-full`}>
                                                                    {noty.badge}
                                                                </p>
                                                            </div>
                                                            <div className="mt-3 text-sm text-gray-500 text-left">{noty.title}</div>
                                                            <p className="text-right font-light text-xs">{noty.time}</p>
                                                        </div>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>

                            {/* Profile button */}
                            <div
                                ref={profileRef}
                                className="flex items-center justify-between ms-3 gap-x-4 bg-gray-200 p-2 text-blue-400 rounded-full hover:bg-blue-300 hover:text-white cursor-pointer"
                                onClick={() => setProfileOpen((prev) => !prev)}
                                aria-expanded={isDropdownOpen}
                            >
                                <span className="sr-only">Open user menu</span>
                                <img
                                    className="w-8 h-8 rounded-full"
                                    src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                                    alt="user photo"
                                />
                                <icons.PI.PiGearSixDuotone size={26} />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Profile Dropdown */}
            {isDropdownOpen && (
                <div className="absolute w-48 mt-1 shadow-xl bg-white divide-y divide-gray-100 rounded shadow right-4 top-15 z-50" ref={profileRef}>
                    <div className="px-4 py-3">
                        <p className="text-sm text-gray-900 dark:text-white">Admin FIDS</p>
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">admin.fids@iasb.com</p>
                    </div>
                    <ul className="py-1">
                        <li>
                            <button
                                type="button"
                                onClick={() => setIsUTC(prev => !prev)}
                                className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300"
                            >
                                {isUTC ? <icons.BS.BsToggleOn className="text-green-500 mr-2" size={25} /> : <icons.BS.BsToggleOff className="text-gray-500 mr-2" size={25} />}
                                <span className="text-gray-500 font-light">{isUTC ? 'UTC Time' : 'MYT Time'}</span>
                            </button>
                        </li>
                        <li>
                            <a href="/dashboard" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                <icons.PI.PiGearLight className="mr-2 text-gray-500" size={20} /> Settings
                            </a>
                        </li>
                        <li>
                            <button onClick={handleSignOut} className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                <icons.PI.PiSignOutLight className="mr-2 text-gray-500" size={20} /> Sign Out
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </header>
    );
}

export default Header;
