import React from "react";
import { icons } from "../../../utils/icons";

interface ClusterListTableProp {
    onView: (isVisible: boolean, clusterId: string) => void;
}

const ClusterListTable: React.FC<ClusterListTableProp> = ({ onView }) => {
    const clusterList = [
        {
            clusterID: 'Cluster_0001',
            devices: 5,
            level: 2,
            location: 'LOC_0001',
            status: 1
        },
        {
            clusterID: 'Cluster_0002',
            devices: 2,
            level: 3,
            location: 'LOC_0002',
            status: 0
        }
    ];

    return (
        <div className="w-full">
            <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-lg text-gray-900 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">Cluster ID</th>
                        <th scope="col" className="px-6 py-3">No. Of Devices</th>
                        <th scope="col" className="px-6 py-3">Floor</th>
                        <th scope="col" className="px-6 py-3">Location ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={5} className="border-t border-gray-400 dark:border-gray-900 my-4 py-2"></td>
                    </tr>
                    {clusterList.map((cluster, index) => (
                        <React.Fragment key={cluster.clusterID || index}>
                            <tr className="dark:bg-gray-800">
                                <td className="px-6 py-2 bg-white rounded-tl-2xl rounded-bl-2xl flex items-center">
                                    {cluster.status ? (
                                        <icons.GO.GoDotFill className="mr-2" style={{ color: '#90ee90' }} />
                                    ) : (
                                        <icons.GO.GoDotFill className="mr-2" style={{ color: '#ee9090' }} />
                                    )}
                                    {cluster.clusterID}
                                </td>
                                <td className="px-6 py-2 bg-white">
                                    {`(${cluster.devices} devices)`}
                                </td>
                                <td className="px-6 py-2 bg-white">{cluster.level}</td>
                                <td className="px-6 py-2 bg-white">{cluster.location}</td>
                                <td className="bg-white rounded-tr-2xl rounded-br-2xl">
                                    <icons.MD.MdKeyboardArrowLeft
                                        size={25}
                                        onClick={() => onView(true, cluster.clusterID)} // Send true when clicked
                                        style={{ cursor: 'pointer' }}
                                    />
                                </td>
                            </tr>
                            <tr className="h-2">
                                <td colSpan={4} className="bg-transparent"></td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ClusterListTable;
