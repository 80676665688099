import React, { useEffect, useState } from "react";
import { db } from "../../../firebase/firebaseConfig";
import { collection, addDoc } from 'firebase/firestore';
import { monitorSize, resolutions, monitorBrands } from "../../../utils/sharedConst";
import toast from 'react-hot-toast';
import { icons } from "../../../utils/icons";

interface NewDeviceModalProp {
    onClose: () => void;
}

const NewDeviceModal: React.FC<NewDeviceModalProp> = ({ onClose }) => {
    const [deviceCode, setDeviceCode] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [modelNo, setModelNo] = useState('');
    const [modelId, setModelId] = useState('');
    const [brand, setBrand] = useState(monitorBrands[0]);
    const [resolution, setResolution] = useState(resolutions[0]);
    const [monitorSizeValue, setMonitorSizeValue] = useState(monitorSize[0]);
    const [orientation, setOrientation] = useState('L');
    const [isFormComplete, setIsFormComplete] = useState(false);

    const onSubmit = async () => {
        try {
            await addDoc(collection(db, "devices"), {
                deviceCode,
                ipAddress,
                serialNumber,
                modelNo,
                modelId,
                brand,
                resolution,
                monitorSize: monitorSizeValue,
                orientation,
            });
            onClose();
            toast.success('Successfully created!', { duration: 4000 });
        } catch (error) {
            toast.error('Error adding document');
        }
    };

    useEffect(() => {
        const checkFormCompletion = () => {
            const isComplete =
                deviceCode.trim() !== "" &&
                ipAddress.trim() !== "" &&
                serialNumber.trim() !== "" &&
                modelNo.trim() !== "" &&
                modelId.trim() !== "";
            setIsFormComplete(isComplete);
        };

        checkFormCompletion();
    }, [deviceCode, ipAddress, serialNumber, modelNo, modelId]);

    return (
        <>
            <div
                id="default-modal"
                tabIndex={-1}
                aria-hidden="true"
                className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
            >
                <div className="relative w-full max-w-xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center md:p-1 rounded-t dark:border-gray-600">
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="default-modal"
                                onClick={onClose}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                            </button>
                        </div>

                        <form>
                            <div className="grid grid-rows-[45px_130px_1fr_1fr] px-4 gap-4">
                                <div className="">
                                    <h6 className="text-sm font-light text-gray-900 dark:text-white">
                                        New Device
                                    </h6>
                                    <div className="flex items-center">
                                        <icons.PI.PiMonitor size={34} className="inline" />
                                        <input
                                            type="text"
                                            id="input1"
                                            value={deviceCode}
                                            onChange={(e) => setDeviceCode(e.target.value)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-xs h-7 rounded-lg focus:ring-blue-500 focus:border-blue-500 ml-4 p-2.5 w-full"
                                            placeholder="Device code"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-rows-[15px_1fr] gap-1">
                                    <div className="text-xs text-gray-600 px-1">Device Property</div>
                                    <div className="grid grid-rows-2 border border-gray-400 rounded-lg shadow-lg py-2">
                                        <div className="flex items-center gap-4 pt-1 px-4 h-7">
                                            <icons.PI.PiWifiHigh size={24} className="inline mt-4" />
                                            <div className="w-full">
                                                <label htmlFor="small-input" className="block text-xs font-light text-gray-500">MAC Address</label>
                                                <input type="text" id="ipAddress" value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} placeholder="00-B0-D0-63-C2-26" required className="block w-full h-7 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                            </div>
                                        </div>

                                        <div className="flex items-center gap-4 px-4 h-7">
                                            <icons.GR.GrConfigure size={24} className="inline mt-4" />
                                            <div className="w-full">
                                                <label htmlFor="small-input" className="block text-xs font-light text-gray-500">Serial Number</label>
                                                <input type="text" id="serialNumber" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} placeholder="Serial Number" required className="block w-full h-7 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="grid grid-rows-[15px_1fr] gap-1">
                                    <div className="text-xs text-gray-600 px-1">Device Property</div>
                                    <div className="grid grid-rows-3 border border-gray-400 rounded-lg shadow-lg gap-2 py-2">
                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.CI.CiHashtag size={20} className="inline" /> <span className="text-sm">Model Number</span></div>
                                            <div>
                                                <input type="text" id="modelNo" value={modelNo} onChange={(e) => setModelNo(e.target.value)} placeholder="Model Number" required className=" h-7 block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.TFI.TfiKey size={20} className="inline" /> <span className="text-sm">Model ID</span></div>
                                            <div>
                                                <input type="text" id="modelId" value={modelId} onChange={(e) => setModelId(e.target.value)} placeholder="Model ID" required className=" h-7 block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.PI.PiHexagonLight size={20} className="inline" /> <span className="text-sm">Brand</span></div>
                                            <div className="w-40">
                                                <select
                                                    id="brand" value={brand} onChange={(e) => setBrand(e.target.value)}
                                                    className="h-7 block w-full text-gray-600 border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    {monitorBrands.map((brand) => (
                                                        <option key={brand} value={brand}>{brand}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-rows-[15px_1fr] gap-1">
                                    <div className="text-xs text-gray-600 px-1">Device Display Settings</div>
                                    <div className="grid grid-rows-3 border border-gray-400 rounded-lg shadow-lg gap-2 py-2">
                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.PI.PiResize size={20} className="inline" /> <span className="text-sm">Resolution</span></div>
                                            <div className="w-40">
                                                <select
                                                    id="resolution" value={resolution} onChange={(e) => setResolution(e.target.value)}
                                                    className="h-7 block w-full text-gray-600 border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    {resolutions.map((res) => (
                                                        <option key={res} value={res}>{res} px</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.PI.PiArrowsOutSimple size={20} className="inline" /> <span className="text-sm">Monitor Size</span></div>
                                            <div className="w-40">
                                                <select
                                                    id="monitorSize" value={monitorSizeValue} onChange={(e) => setMonitorSizeValue(Number(e.target.value))}
                                                    className="h-7 block w-full text-gray-600 border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    {monitorSize.map((size) => (
                                                        <option key={size} value={size}>{size} inches</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div>
                                                <icons.PI.PiArrowsClockwise size={20} className="inline" /> <span className="text-sm">Orientation</span>
                                            </div>
                                            <div className="w-40">
                                                <select
                                                    id="orientation"
                                                    value={orientation}
                                                    onChange={(e) => setOrientation(e.target.value)}
                                                    className="h-7 block w-full text-gray-600 border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    <option value={'L'}>Landscape</option>
                                                    <option value={'P'}>Portrait</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="flex justify-end px-4 py-3 md:p- dark:border-gray-600">
                            <button
                                data-modal-hide="default-modal"
                                type="button"
                                className={`text-white ${isFormComplete
                                    ? "bg-blue-700 hover:bg-blue-800"
                                    : "bg-gray-400"
                                    } focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700`}
                                onClick={onSubmit}
                                disabled={!isFormComplete}
                            >
                                Save
                            </button>
                            <button
                                data-modal-hide="default-modal"
                                type="button"
                                className="py-1.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewDeviceModal;
