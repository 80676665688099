import ApexCharts from "apexcharts";
import { useEffect } from "react";

const OverViewAnalysis = () => {
    const getChartOptions = () => {
        return {
            series: [52.8, 26.8],
            colors: ["#1C64F2", "#16BDCA"],
            chart: {
                height: 420,
                width: "100%",
                type: "pie",
            },
            stroke: {
                colors: ["white"],
                lineCap: "",
            },
            plotOptions: {
                pie: {
                    labels: {
                        show: true,
                    },
                    size: "100%",
                    dataLabels: {
                        offset: -25
                    }
                },
            },
            labels: ["Online", "Offline"],
            dataLabels: {
                enabled: true,
                style: {
                    fontFamily: "Inter, sans-serif",
                },
            },
            legend: {
                position: "bottom",
                fontFamily: "Inter, sans-serif",
            },
            yaxis: {
                labels: {
                    formatter: function (value: string) {
                        return value + "%"
                    },
                },
            },
            xaxis: {
                labels: {
                    formatter: function (value: string) {
                        return value + "%"
                    },
                },
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
            },
        };
    };

    useEffect(() => {
        const chart = new ApexCharts(document.getElementById("pie-chart"), getChartOptions());
        chart.render();
        return () => {
            chart.destroy();
        };
    }, []);

    return (
        <>
            <div className="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
                <div className="flex justify-between items-start w-full">
                    <div className="flex-col items-center">
                        <div className="flex items-center mb-1">
                            <h5 className="text-xl font-bold leading-none text-gray-500 dark:text-white me-1">Analysis</h5>
                        </div>
                        <div className="flex items-center mb-1">
                            <h6 className="text-xl font-bold leading-none text-gray-900 dark:text-white me-1">Device Status</h6>
                        </div>

                        {/* Date range dropdown omitted for brevity */}
                    </div>
                    <div className="flex-col items-center">
                        <div className="flex items-center mb-1">
                            <h5 className="text-xl font-bold leading-none text-gray-500 dark:text-white me-1">Total Devices</h5>
                        </div>
                        <div className="text-right">
                            <h6 className="text-xl font-bold leading-none text-gray-900 dark:text-white me-1">300</h6>
                        </div>

                    </div>
                </div>

                <div className="pt-4 border-gray-200 border-b" />

                <button id="dateRangeButton" data-dropdown-toggle="dateRangeDropdown" data-dropdown-ignore-click-outside-class="datepicker" type="button" className="pt-4 inline-flex items-center text-blue-700 dark:text-blue-600 font-medium hover:underline">31 Nov - 31 Dev <svg className="w-3 h-3 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                </svg>
                </button>

                <div className="py-6" id="pie-chart"></div>
            </div>
        </>
    );
};

export default OverViewAnalysis;
