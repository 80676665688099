import { useState, useEffect } from "react";
import { icons } from "../../../utils/icons";

const NewClusterLocation = () => {
    const [floorLevel, setFloorLevel] = useState<number>(1);
    const [zoom, setZoom] = useState<number>(1);
    const [clickPosition, setClickPosition] = useState<{ x: number; y: number } | null>(null);

    const handleWheel = (e: WheelEvent) => {
        e.preventDefault();
        if (e.deltaY < 0) {
            setZoom((prevZoom) => Math.min(prevZoom + 0.05, 3));
        } else {
            setZoom((prevZoom) => Math.max(prevZoom - 0.05, 1)); 
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        setClickPosition({ x, y });
        console.log(`Clicked at position: (${x}, ${y})`);
    };

    useEffect(() => {
        window.addEventListener("wheel", handleWheel, { passive: false });
        return () => {
            window.removeEventListener("wheel", handleWheel);
        };
    }, []);

    return (
        <div className="relative" style={{ height: '340px', width: '1080px' }}>
            <div className="w-full h-full"
                onClick={handleClick}
                style={{
                    backgroundImage: `url(${require(`../../../assets/${floorLevel}.png`)})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    transform: `scale(${zoom})`,
                    transformOrigin: 'center',
                }}>
                {clickPosition && (
                    <div
                        style={{
                            position: 'absolute',
                            top: clickPosition.y,
                            left: clickPosition.x,
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <icons.PI.PiMapPinDuotone style={{ color: 'red' }} size={20} />
                    </div>
                )}
            </div>
            {zoom === 1 &&
                <span className="absolute right-0 top-0">
                    <select
                        onChange={(e) => { setFloorLevel(Number(e.currentTarget.value)); }}
                        className="text-xs font-light text-gray-900 bg-white shadow-lg hover:bg-gray-100 rounded-xl text-xs px-1.5 py-1.5 text-center inline-flex items-center"
                    >
                        <option selected value={1}>Level 1</option>
                        <option value={2}>Level 2</option>
                        <option value={3}>Level 3</option>
                        <option value={4}>Level 4</option>
                    </select>
                </span>}
        </div>
    );
};

export default NewClusterLocation;
