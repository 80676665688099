import React, { useRef, useEffect, useState } from "react";

interface CanvasTemplateProps {
    height?: number; // Optional height prop
    width?: number;  // Optional width prop
}

const CanvasTemplate: React.FC<CanvasTemplateProps> = ({ height = 100, width = 100 }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [availableSize, setAvailableSize] = useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    });

    // Function to update the available size
    const updateSize = () => {
        if (containerRef.current) {
            const { clientWidth, clientHeight } = containerRef.current;
            setAvailableSize({
                width: clientWidth,
                height: clientHeight,
            });
        }
    };

    useEffect(() => {
        updateSize(); // Initial size calculation when the component mounts

        // Create a ResizeObserver to automatically detect size changes
        const resizeObserver = new ResizeObserver(() => {
            updateSize(); // Update size on resize
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current); // Observe size changes of the container
        }

        // Add fullscreen change event listeners
        const handleFullscreenChange = () => {
            updateSize(); // Update size when entering/exiting fullscreen
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
        document.addEventListener("mozfullscreenchange", handleFullscreenChange);
        document.addEventListener("MSFullscreenChange", handleFullscreenChange);

        return () => {
            resizeObserver.disconnect(); // Clean up the observer
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
            document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
            document.removeEventListener("MSFullscreenChange", handleFullscreenChange);
        };
    }, []);

    // Calculate the scaling factor to ensure the box fits within the available area
    const scaleFactor = Math.min(
        availableSize.width / width || 1,
        availableSize.height / height || 1
    );

    const scaledWidth = width * scaleFactor;
    const scaledHeight = height * scaleFactor;

    return (
        <div
            ref={containerRef}
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
            }}
        >
            {/* The blue box */}
            <div
                style={{
                    width: scaledWidth,
                    height: scaledHeight,
                    backgroundColor: "#f2f2f2",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)", // Center the box
                }}>
                {/* Display the scaled width on top */}
                <div
                    style={{
                        position: "absolute",
                        top: "10px", // Adjust the vertical position
                        left: "50%",
                        transform: "translateX(-50%)",
                        color: "black",
                        backgroundColor: "white",
                        padding: "2px 4px",
                        fontSize: "12px",
                        border: "1px solid #ccc",
                    }}
                >
                    Width: {Math.round(scaledWidth)}px
                </div>

                {/* Display the scaled height on the right */}
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px", // Adjust the horizontal position
                        transform: "translateY(-50%)",
                        color: "black",
                        backgroundColor: "white",
                        padding: "2px 4px",
                        fontSize: "12px",
                        border: "1px solid #ccc",
                    }}
                >
                    Height: {Math.round(scaledHeight)}px
                </div>
            </div>
        </div>
    );
};

export default CanvasTemplate;
