import { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const TotalFlight = () => {
  useEffect(() => {
    // Options for the chart
    const options = {
      chart: {
        type: "line",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        height: '100%', // Allow the chart to take full height of the container
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 4, // Reduced stroke width for better fitting
      },
      grid: {
        show: true,
        strokeDashArray: 4,
        padding: {
          left: 10,
          right: 10,
          top: 0, // Increased top padding to ensure x-axis labels are visible
          bottom: 20,
        },
      },
      series: [
        {
          name: "Departure",
          data: [6500, 6418, 6456, 6526, 6356, 6456],
          color: "#1A56DB",
        },
        {
          name: "Arrival",
          data: [6456, 6356, 6526, 6332, 6418, 6500],
          color: "#7E3AF2",
        },
      ],
      xaxis: {
        categories: ['01 Feb', '02 Feb', '03 Feb', '04 Feb', '05 Feb', '06 Feb', '07 Feb'],
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400',
          },
          rotate: -45, // Prevent overlap by rotating labels
          offsetY: 5, // Adjust label position to ensure visibility
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
      },
      legend: {
        show: true, // Ensure legend is displayed
        position: 'bottom', // Place the legend at the bottom to avoid overlapping with the chart
      },
    };

    // Render the chart in the correct div
    const chart = new ApexCharts(document.getElementById("line-chart"), options);
    chart.render();

    // Cleanup on unmount
    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <div className="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-2 flex flex-col h-full">
      <div className="flex justify-between mb-2 "> 
        <div className="grid gap-3 grid-cols-2"> 
          <div>
            <h5 className="inline-flex items-center text-gray-900 dark:text-gray-400 leading-none font-bold mb-1">
              Departure Flights
            </h5>
            <p className="text-gray-500 dark:text-white text-l leading-none font-bold">1344</p>
          </div>
          <div>
            <h5 className="inline-flex items-center text-gray-900 dark:text-gray-400 leading-none font-bold mb-1">
              Arrival Flights
            </h5>
            <p className="text-gray-500 dark:text-white text-l leading-none font-bold">1281</p>
          </div>
        </div>
      </div>

      {/* Chart Container */}
      <div id="line-chart" className="w-full"> {/* Adjusted height for better fitting */}
        {/* The chart will render here */}
      </div>
    </div>
  );
};

export default TotalFlight;
