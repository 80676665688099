import AoFDC from "../components/Aofc";
import Dayxhour from "../components/dayxhour";
import Domvsint from "../components/DomvsInt";
import Filter from "../components/Filter";
import Top5 from "../components/Top5";
import TopAirline from "../components/topAirline";
import TotalFlight from "../components/TotalFlight";

const Dashboard = () => {
  return (
    <div className="flex items-center justify-center overflow-hidden h-full"> 
    <div className="grid grid-cols-1 md:grid-cols-5 gap-2 w-full h-full"> {/* Responsive grid layout */}
      
      {/* Filter Component */}
      <div className="md:col-span-1 h-full shadow-md bg-white rounded-lg"> 
        <Filter />
      </div>
  
      {/* Main Content */}
      <div className="md:col-span-4 h-full flex flex-col">
        <div className="grid grid-rows-2 gap-4 h-full"> 
          
          {/* First Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full"> {/* Responsive columns */}
            <div className="h-full shadow-md hover:shadow-xl bg-white rounded-lg flex items-stretch"> 
              <TotalFlight />
            </div>
            <div className="h-full shadow-md hover:shadow-xl bg-white rounded-lg flex items-stretch"> 
              <Domvsint />
            </div>
          </div>
  
          {/* Second Row */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 h-full"> {/* Responsive columns */}
            <div className="h-full shadow-md hover:shadow-xl bg-white rounded-lg flex items-stretch"> 
              <TopAirline />
            </div>
            <div className="col-span-1 md:col-span-2 h-full shadow-md hover:shadow-xl bg-white rounded-lg flex items-stretch"> 
              <AoFDC />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  
  );
};

export default Dashboard;
  