import React, { useState } from "react";
import { icons } from "../../../utils/icons";
import AddDeviceCluster from "./addDeviceCluster";
import Confirmation from "../../../components/shared/confirmationDialog";

interface Device {
    bankId: number
    clientId: string
    clusterId: string
    deviceCode: string
    ipAdress: string
    name: string
    sequence: number
    status: number
}
interface DeviceOverviewProp {
    onClose: () => void;
    device: Device
}

const DeviceOverview: React.FC<DeviceOverviewProp> = ({ onClose, device }) => {
    const [isActive, setIsActive] = useState<boolean>(true)
    const [deviceDetail, setDeviceDetail] = useState<boolean>(false)
    const [addDevice, setAddDevice] = useState<boolean>(false)
    const [onConfirm, setOnConfirm] = useState<boolean>(false)
    const [isSecondPage, setSecondPage] = useState<boolean>(false)
    const buttonCount = device.bankId > 0 ? 4 : 1;

    return (
        <div className="absolute top-0 left-0">
            <div className="grid grid-cols-2">
                <div className="flex flex-col w-96 min-h-[calc(100vh-80px)] p-2 bg-white">
                    <div className="h-8 flex items-center justify-end pt-2">
                        <button
                            type="button"
                            className="flex items-center cursor-pointer transition-colors duration-300 rounded-full rounded-3xl text-sm px-1 py-1 me-2 mb-2"
                            onClick={() => { setIsActive(prev => !prev); }}
                        >
                            {isActive ? (
                                <icons.BS.BsToggleOn className="text-green-500" size={30} />
                            ) : (
                                <icons.BS.BsToggleOff className="text-gray-500" size={30} />
                            )}
                        </button>

                        <button type="button" onClick={() => { setAddDevice(true); setDeviceDetail(false) }} className="text-gray-900 bg-gray-100 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-3xl text-sm px-1 py-1 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                            <icons.MD.MdAddCircleOutline className="text-gray-500" />
                        </button>
                        <button type="button" onClick={onClose} className="text-gray-900 bg-gray-100 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-3xl text-sm px-1 py-1 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                            <icons.MD.MdFilterList className="text-gray-500" />
                        </button>
                        <button type="button" onClick={onClose} className="text-gray-900 bg-gray-100 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-3xl text-sm px-1 py-1 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                            <icons.MD.MdClose className="text-gray-500" />
                        </button>
                    </div>

                    <div className="h-5 flex items-center px-4 mb-2 font-medium"><icons.GO.GoDotFill className="mr-2" style={{ color: '#90ee90' }} />{device.clusterId}&nbsp;&nbsp;<small className="text-gray-400">(5 device)</small></div>

                    <div className="bg-slate-200 flex-grow p-4 rounded-lg shadow-lg">
                        {Array.from({ length: buttonCount }).map((_, index) => (
                            <div key={index} className="mb-4">
                                <button
                                    className="bg-white rounded-lg p-4 shadow-md w-full h-20 flex items-center justify-center hover:shadow-2xl"
                                    onClick={() => { setDeviceDetail(true); }}
                                >
                                    <div className="grid grid-cols-5 w-full">
                                        <div className="col-span-4">
                                            <div className="grid grid-rows-2">
                                                <div className="flex justify-between items-center">
                                                    <div className="font-medium">{device.clientId}</div>
                                                    <span className="bg-white text-green-400 border border-gray-200 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                                        <div className="flex items-center">
                                                            <icons.GO.GoDotFill style={{ color: '#90ee90' }} />
                                                            {device.status === 1 ? 'Online' : 'Offline'}
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <p className="font-medium text-xs">
                                                        Device ID:&nbsp;&nbsp;&nbsp;<span className="text-gray-400">Device_{index + 1}</span>
                                                    </p>
                                                    <span className="bg-white text-green-400 border border-gray-200 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                                        Stable
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end">
                                            <div className="w-14 h-14 bg-white border-4 border-green-500 rounded-full shadow-lg flex items-center justify-center">
                                                <span className="text-lg font-bold">{index + 1}/{buttonCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        ))}
                    </div>

                </div>

                {deviceDetail && <div className="relative w-full">
                    <div id="select-modal" tabIndex={-1} aria-hidden="true" className="overflow-y-auto overflow-x-hidden top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                        <div className="relative w-full max-w-md max-h-full top-24">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="grid grid-rows-[30px_80px_205px_150px_1fr] p-1 pb-3">

                                    <div className="flex justify-between">
                                        <small className="text-gray-400 text-xs font-medium">Device Properties</small>
                                        <div> <button type="button" onClick={() => { setDeviceDetail(false) }} className="text-gray-900 bg-gray-100 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-3xl text-sm px-1 py-1 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                            <icons.MD.MdClose className="text-gray-500" />
                                        </button></div>
                                    </div>

                                    <div className="px-2">
                                        <div className="grid grid-cols-8 w-full">
                                            <div className="col-span-7">
                                                <div className="grid grid-rows-2">
                                                    <div className="grid grid-cols-3">
                                                        <div className="col col-span-2 font-semibold">
                                                            <small>BANK001&nbsp;&nbsp;</small>
                                                            <svg width="10" className="inline mr-1" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34154 1.69556C5.33757 1.75505 5.33555 1.81486 5.33555 1.87491C5.33555 2.61458 5.93517 3.2142 6.67484 3.2142C6.7059 3.2142 6.73672 3.21314 6.76726 3.21106C6.76518 3.2416 6.76412 3.27242 6.76412 3.30349V4.07729C6.76412 4.26786 6.80392 4.44912 6.87566 4.61324C6.6932 4.86375 6.58555 5.17225 6.58555 5.50589C6.58555 6.34419 7.26515 7.02375 8.10343 7.02375C8.785 7.02375 9.36165 6.5745 9.55343 5.95595V7.40984C9.55343 7.60706 9.3935 7.76699 9.19629 7.76699H0.803432C0.606188 7.76699 0.446289 7.60706 0.446289 7.40984V2.0527C0.446289 1.85546 0.606188 1.69556 0.803432 1.69556H5.34154Z" fill="#7AF1FF" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.74914 0.967622C7.92864 0.89328 8.12607 0.873837 8.31657 0.91173C8.50714 0.949622 8.68214 1.04317 8.8195 1.18052C8.95686 1.31788 9.05036 1.49288 9.08829 1.68339C9.12614 1.87391 9.10671 2.07139 9.03236 2.25085C8.95807 2.43032 8.83214 2.5837 8.67064 2.69162C8.50914 2.79954 8.31921 2.85714 8.125 2.85714C7.87843 2.85714 7.67857 3.05702 7.67857 3.30357V4.07738C7.67857 4.32394 7.87843 4.52381 8.125 4.52381C8.37157 4.52381 8.57143 4.32394 8.57143 4.07738V3.69608C8.7825 3.64434 8.98407 3.55602 9.16671 3.43401C9.475 3.22798 9.71536 2.93514 9.85729 2.59253C9.99922 2.24992 10.0363 1.87292 9.964 1.50921C9.89164 1.14549 9.71307 0.811401 9.45086 0.549175C9.18857 0.286952 8.8545 0.108376 8.49079 0.0360285C8.12707 -0.0363189 7.75007 0.000812454 7.4075 0.142727C7.06486 0.284641 6.77202 0.524965 6.56599 0.833308C6.35996 1.14165 6.25 1.50416 6.25 1.875C6.25 2.12156 6.44987 2.32143 6.69643 2.32143C6.94299 2.32143 7.14286 2.12156 7.14286 1.875C7.14286 1.68075 7.20043 1.49087 7.30836 1.32935C7.41629 1.16784 7.56971 1.04196 7.74914 0.967622ZM8.125 6.13098C7.77986 6.13098 7.5 5.85116 7.5 5.50598C7.5 5.1608 7.77986 4.88098 8.125 4.88098C8.47014 4.88098 8.75 5.1608 8.75 5.50598C8.75 5.85116 8.47014 6.13098 8.125 6.13098ZM9.19643 8.2135H6.27751L6.63466 9.10636H7.14286C7.38943 9.10636 7.58929 9.30622 7.58929 9.55279C7.58929 9.79936 7.38943 9.99922 7.14286 9.99922H2.85714C2.61059 9.99922 2.41071 9.79936 2.41071 9.55279C2.41071 9.30622 2.61059 9.10636 2.85714 9.10636H3.36534L3.72249 8.2135H0.803571C0.359771 8.2135 0 7.85372 0 7.40993V2.05279C0 1.60899 0.359771 1.24922 0.803571 1.24922H5C5.24656 1.24922 5.44643 1.44909 5.44643 1.69564C5.44643 1.9422 5.24656 2.14207 5 2.14207H0.892857V7.32064H9.10714V6.69564C9.10714 6.44909 9.307 6.24922 9.55357 6.24922C9.80014 6.24922 10 6.44909 10 6.69564V7.40993C10 7.85372 9.64021 8.2135 9.19643 8.2135Z" fill="#0C098C" />
                                                            </svg>
                                                            <small className="font-light text-xs">
                                                                Philips Monitor
                                                            </small>
                                                        </div>
                                                        <div><small>Device Type Code</small></div>
                                                    </div>

                                                    <div className="grid grid-cols-3">

                                                        <div className="flex items-center space-x-2 col col-span-2 text-md">
                                                            <div>Device033</div>
                                                            <div className="flex items-center text-sm bg-white border border-gray-200 text-xs font-medium px-1 py-0.5 rounded">
                                                                <icons.GO.GoDotFill style={{ color: '#90ee90' }} />
                                                                Online
                                                            </div>
                                                            <span className="bg-white text-green-400 border border-gray-200 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                                                Stable
                                                            </span>
                                                        </div>
                                                        <div><small className="border border-gray-400 m-1">Bank Of Monitor</small></div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="flex justify-end">
                                                <div className="grid grid-rows-2">
                                                    <p className="flex justify-center">
                                                        <button type="button" onClick={() => { setOnConfirm(prev => !prev) }} className="shadow-lg text-gray-900 bg-gray-100 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-3xl text-sm px-1 py-1  dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                            <icons.GR.GrConfigure className="text-gray-500" />
                                                        </button>
                                                    </p>
                                                    <p><small className="text-gray-400 text-xs font-medium">configure</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-300 rounded-xl text-center font-light text-sm my-2"><span>Display Preview</span></div>
                                    </div>

                                    <div className="px-2">
                                        <div className="bg-gray-600 rounded-md p-1">
                                            <div className="w-full border-2 border-black h-48">
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${require(`../../../assets/previewScreen.png`)})`,
                                                        height: '100%',
                                                        width: '100%',
                                                        backgroundPosition: 'center',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover',
                                                    }}
                                                ></div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="px-2 py-1">
                                        <div className="inline-flex items-center justify-between w-full p-2 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                                            <div className="grid grid-rows-4 grid-flow-col w-full m-2">
                                                <div className="border-b border-gray-200 w-full flex justify-between items-center">
                                                    <p className="inline font-semibold"><icons.MD.MdWifi size={26} className="inline pb-1 text-green-500" /> 172.182.254.1</p>
                                                    <p className="inline"><icons.MD.MdRssFeed size={16} className="inline pb-1 text-green-500" />Channel 2</p>
                                                    <p className="inline"><icons.MD.MdMyLocation size={16} className="inline pb-1 text-green-500" />LOC00001</p>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <p className="font-light text-xs">Display Label Code</p>
                                                    <p className="font-semibold text-sm">GATEENT</p>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <p className="font-light text-xs">Client Type</p>
                                                    <p className="font-semibold text-sm">GATEENT</p>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <p className="font-light text-xs">Template ID</p>
                                                    <p className="font-semibold text-sm">GATEENT</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-2 py-2">
                                        <span className="font-light text-md text-gray-400 ">Other Properties</span>
                                        <div className="inline-flex items-center justify-between w-full text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                                            <div className="bg-white w-full rounded-lg shadow-md p-2 col-span-2">
                                                <div className="grid grid-cols-[80px_0.5fr_0.5fr_0.8fr] gap-4">
                                                    <div className="flex items-center">
                                                        <button
                                                            type="button"
                                                            className="flex items-center cursor-pointer transition-colors duration-300 rounded-full rounded-3xl text-sm px-1 py-1"
                                                            onClick={() => { setSecondPage(prev => !prev); }}
                                                        >
                                                            {isSecondPage ? (
                                                                <icons.BS.BsToggleOn className="text-green-500" size={30} />
                                                            ) : (
                                                                <icons.BS.BsToggleOff className="text-gray-500" size={30} />
                                                            )}
                                                        </button>
                                                        <span className="text-xs text-gray-400 pl-2">
                                                            2nd Page
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="small-input" className="text-xs font-medium text-gray-900 dark:text-white mr-2">1st interval</label>
                                                        <input disabled={!isSecondPage} type="number" id="small-input" className="block w-full p-1 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="60s" />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="small-input" className="text-xs font-medium text-gray-900 dark:text-white mr-2">2nd interval</label>
                                                        <input type="number" disabled={!isSecondPage} id="small-input" className="block w-full p-1 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="60s" />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="small-input" className="text-xs font-medium text-gray-900 dark:text-white mr-2">Transition</label>
                                                        <select id="template_group" disabled={!isSecondPage} className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                            <option disabled selected>--Transition</option>
                                                            <option>Top-Bottom</option>
                                                            <option>Bottom-Top</option>
                                                            <option>Flip</option>
                                                            <option>Blink</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between items-center pt-2">
                                                    <p className="text-sm font-normal text-gray-600">Template Interval :</p>
                                                    <div>
                                                        <form className="max-w-sm mx-auto">
                                                            <select id="template_group" disabled={!isSecondPage} className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                <option disabled selected>--Template</option>
                                                                <option>Air Asia BIG Offer !</option>
                                                                <option>Gate_Uni</option>
                                                                <option>Arrival</option>
                                                                <option>Latest_Promo</option>
                                                            </select>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-between">
                                        <div> <button type="button" onClick={() => { setDeviceDetail(false) }} >
                                            <icons.MD.MdArrowLeft className="text-gray-500" size={32} />
                                        </button></div>
                                        <div> <button type="button" onClick={() => { setDeviceDetail(false) }} >
                                            <icons.MD.MdBrightness1 className="text-gray-500 mt-2" />
                                        </button></div>
                                        <div> <button type="button" onClick={() => { setDeviceDetail(false) }} >
                                            <icons.MD.MdArrowRight className="text-gray-500" size={32} />
                                        </button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>

            {addDevice && <AddDeviceCluster onClose={() => { setAddDevice(false) }} />}
            {onConfirm && <Confirmation isOpen={onConfirm} onClose={() => { setOnConfirm(false) }} title="Save Configuration ?" message="Are you sure want to safe this configuration?" onConfirm={() => { setOnConfirm(false) }} />}
        </div>
    );
}

export default DeviceOverview;
