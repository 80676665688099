import React, { useEffect, useState } from 'react';
import { AiFillAppstore } from 'react-icons/ai';
import { icons } from '../../utils/icons';
import { useNavigate } from 'react-router-dom';

const Sidebar: React.FC = () => {
    const [pPath, setPPath] = useState('');
    const [cPath, setCPath] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const pathname = window.location.pathname;
        const parts = pathname.split('/');
        const newPPath = parts[1];
        const newCPath = parts[2];

        setPPath(newPPath);
        setCPath(newCPath);
    }, []);

    const [openSection, setOpenSection] = useState<string | null>(null);

    const toggleSection = (section: string) => {
        setOpenSection((prevSection) => (prevSection === section ? null : section));
    };

    const handleFlightDisplayClick = () => {
        navigate('/flight-info-display');
        toggleSection('flight_display');
    };

    return (
        <div className="w-64 h-full p-3 bg-white flex flex-col justify-between">
            <div>
                <div className='border-b border-gray-200 my-2 py-2'>
                    <h3 className="mt-4 mb-1 text-sm font-semibold text-gray-600">Home</h3>
                    <div className="mb-4">
                        <div className={`flex items-center p-2 rounded-lg cursor-pointer text-purple-600 hover:bg-purple-100 ${pPath === 'dashboard' ? 'bg-purple-100' : ''}`}>
                            <AiFillAppstore size={24} />
                            <a href="/dashboard" className="ml-3 text-sm font-semibold text-gray-800">
                                Dashboard
                            </a>
                        </div>
                    </div>
                </div>

                <div className='border-b border-gray-200 my-2 py-2'>
                    <h3 className="mt-4 mb-1 text-sm font-semibold text-gray-600">Tams</h3>
                    <div className="mb-4">
                        <div
                            className={`flex items-center p-2 rounded-lg cursor-pointer hover:bg-purple-100 ${pPath === 'flight-info-management' ? 'bg-purple-100' : ''}`}
                            onClick={() => toggleSection('flight_management')}
                        >
                            <icons.MD.MdFlight size={24} className="text-purple-600" />
                            <span className="ml-3 text-sm font-semibold text-gray-800">Flight Management</span>
                            <span className="ml-auto text-gray-600">{openSection === 'flight_management' ? (<icons.PI.PiCaretUpLight size={16} />) : (<icons.PI.PiCaretDownLight size={16} />)}</span>
                        </div>
                        {openSection === 'flight_management' && (
                            <div className="pl-6">
                                <ul className='border-l border-gray-200 pl-3'>
                                    <li className='pb-2'>
                                        <a href="/flight-info-management/active-flight" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Active Flight
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Flight Schedule
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Flight Tracker
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="mb-4">
                        <div
                            className={`flex items-center p-2 rounded-lg cursor-pointer hover:bg-purple-100 ${pPath === 'flight-info-display' ? 'bg-purple-100' : ''}`}
                            onClick={handleFlightDisplayClick} // Use the click handler
                        >
                            <icons.MD.MdDisplaySettings size={24} className="text-purple-600" />
                            <span className="ml-3 text-sm font-semibold text-gray-800">Flight Display</span>
                            <span className="ml-auto text-gray-600">{openSection === 'flight_display' ? (<icons.PI.PiCaretUpLight size={16} />) : (<icons.PI.PiCaretDownLight size={16} />)}</span>
                        </div>
                        {openSection === 'flight_display' && (
                            <div className="pl-6">
                                <ul className='border-l border-gray-200 pl-3'>
                                    <li className='pb-2'>
                                        <a href="/flight-info-display/device-manager" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Device Manager
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/flight-info-display/template-editor" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Screen Editor
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/flight-info-display/clusters" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Cluster List
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/flight-info-display" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Display Rules Editor
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

                <div className='border-b border-gray-200 my-2 py-2'>
                    <h3 className="mt-4 mb-1 text-sm font-semibold text-gray-600">Management</h3>

                    <div className="mb-4">
                        <div
                            className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-purple-100"
                            onClick={() => toggleSection('resources')}
                        >
                            <icons.MD.MdOutlineSettingsAccessibility size={24} className="text-purple-600" />
                            <span className="ml-3 text-sm font-semibold text-gray-800">Resources</span>
                            <span className="ml-auto text-gray-600">{openSection === 'resources' ? (<icons.PI.PiCaretUpLight size={16} />) : (<icons.PI.PiCaretDownLight size={16} />)}</span>
                        </div>
                        {openSection === 'resources' && (
                            <div className="pl-6">
                                <ul className='border-l border-gray-200 pl-3'>
                                    <li className='pb-2'>
                                        <a href="/" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Gate & Stand
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Check In Counter
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Man Power
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Capacity Planner
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <div
                            className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-purple-100"
                            onClick={() => toggleSection('airport')}
                        >
                            <icons.MD.MdOutlineRoomPreferences size={24} className="text-purple-600" />
                            <span className="ml-3 text-sm font-semibold text-gray-800">Airport</span>
                            <span className="ml-auto text-gray-600">{openSection === 'airport' ? (<icons.PI.PiCaretUpLight size={16} />) : (<icons.PI.PiCaretDownLight size={16} />)}</span>
                        </div>
                        {openSection === 'airport' && (
                            <div className="pl-6">
                                <ul className='border-l border-gray-200 pl-3'>
                                    <li className='pb-2'>
                                        <a href="/" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • All
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Grounding Handling
                                        </a>
                                    </li>
                                    <li className='pb-2'>
                                        <a href="/" className="block py-1 text-sm text-gray-700 hover:text-purple-600">
                                            • Boarding
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>


                <div className="mb-4 mt-4">
                    <h3 className="mt-4 mb-1 text-sm font-semibold text-gray-600">Utilities</h3>
                    <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-purple-100">
                        <icons.MD.MdOutlineFilterDrama size={24} className="text-purple-600" />
                        <a href="/" className="ml-3 text-sm font-semibold text-gray-800">
                            Weather Information
                        </a>
                    </div>
                    <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-purple-100">
                        <icons.MD.MdOutlineDiversity3 size={24} className="text-purple-600" />
                        <a href="/" className="ml-3 text-sm font-semibold text-gray-800">
                            User Management
                        </a>
                    </div>
                    <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-purple-100">
                        <icons.MD.MdOutlineReportProblem size={24} className="text-purple-600" />
                        <a href="/" className="ml-3 text-sm font-semibold text-gray-800">
                            Incident Reporting
                        </a>
                    </div>
                    <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-purple-100">
                        <icons.MD.MdOutlinePhoneInTalk size={24} className="text-purple-600" />
                        <a href="/" className="ml-3 text-sm font-semibold text-gray-800">
                            Help & Support
                        </a>
                    </div>
                </div>
            </div>


            {/* Bottom Section */}
            <div className="text-center text-sm text-gray-500 flex items-center justify-center">
                <img src={require('../../assets/iasb.png')} alt="Company Logo" className="w-4 h-4 mr-1" />
                TAMS v1.0.0 <span className="text-xs text-gray-400 ml-1">by IASB</span>
            </div>


        </div>
    );
};

export default Sidebar;
