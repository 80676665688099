import { icons } from "../../../utils/icons"

const OfflineDeviceMap = () => {
    return (<>
        <div className="w-full relative">
            <a
                href="/flight-info-display/clusters"
                className="block w-full p-3 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 relative" // Added relative positioning here
                style={{
                    height: '380px',
                    backgroundImage: `url(${require(`../../../assets/locationTagging.png`)})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="absolute inset-0 bg-white opacity-50 rounded-lg hover:opacity-20 transition-opacity duration-200" />
                <p className="mb-2 text-3xl font-bold tracking-tight text-gray-500 absolute top-5 right-4">Location Tagging</p>
                <small className="mb-2 tracking-tight text-gray-700 absolute bottom-10 left-4">Offline Devices</small>
                <icons.MD.MdArrowOutward className="absolute bottom-4 right-2 text-gray-900 text-4xl"/>
            </a>
        </div>
    </>)
}

export default OfflineDeviceMap