import React, { useEffect, useState } from "react";
import { icons } from "../../../utils/icons";
import { onSnapshot, collection } from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";

interface AddDeviceClusterProp {
    onClose: () => void
}

const AddDeviceCluster: React.FC<AddDeviceClusterProp> = ({ onClose }) => {
    const [data, setData] = useState<any[]>([]);
    const [isActive, setIsActive] = useState<boolean>(false)
    const [isSecondPage, setSecondPage] = useState<boolean>(false)

    const [checkedDevices, setCheckedDevices] = useState<Record<string, boolean>>({});

    const handleCheckboxChange = (deviceId: string) => {
        setCheckedDevices((prev) => ({
            ...prev,
            [deviceId]: !prev[deviceId],
        }));
    };

    const checkedCount = Object.values(checkedDevices).filter(Boolean).length;

    useEffect(() => {
        const res = onSnapshot(collection(db, 'devices'), (snapshot) => {
            const devices = snapshot.docs.map((doc) => ({
                ...doc.data()
            }));
            setData(devices);
        }, (error) => {
            console.log('Error');
        });
        return () => {
            res();
        }
    }, []);

    return (
        <div>
            <div
                id="static-modal"
                data-modal-backdrop="static"
                tabIndex={-1}
                aria-hidden="true"
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            >
                <div className="relative p-4 w-full max-w-6xl max-h-full">
                    <div className="relative bg-gray-100 rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-3 rounded-t dark:border-gray-600">
                            <div className="flex items-center">
                                <div
                                    style={{
                                        backgroundImage: `url(${require(`../../../assets/icon_1.png`)})`,
                                        height: '30px',
                                        width: '30px',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                    }}
                                    className="mr-2"
                                ></div>

                                <h3 className="text-normal font-semibold text-gray-900 dark:text-white">
                                    Select Device
                                </h3>
                            </div>

                            <button onClick={onClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4">
                            <div className="grid grid-rows-[340px_1fr]">
                                <div className="">
                                    <div className="grid grid-cols-2 gap-4 relative">
                                        {/* Table */}
                                        <div className="rounded-md">
                                            <div className="overflow-x-auto">
                                                <table className="min-w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                        <tr>
                                                            <th scope="col" className="p-4">
                                                                <div className="flex items-center">
                                                                    <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                                                </div>
                                                            </th>
                                                            <th scope="col" className="px-3 py-3">
                                                                DEVICE CODE
                                                            </th>
                                                            <th scope="col" className="px-3 py-3">
                                                                MAC Address
                                                            </th>
                                                            <th scope="col" className="px-3 py-3">
                                                                Resolution
                                                            </th>
                                                            <th scope="col" className="px-3 py-3">
                                                                Size
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((device, index) => (
                                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                                <td className="w-4 p-4">
                                                                    <div className="flex items-center">
                                                                        <input
                                                                            id={`${device.name}_${index}`}
                                                                            type="checkbox"
                                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                            checked={!!checkedDevices[device.deviceCode]}
                                                                            onChange={() => handleCheckboxChange(device.deviceCode)}
                                                                        />
                                                                        <label htmlFor="checkbox-table-search-2" className="sr-only">checkbox</label>
                                                                    </div>
                                                                </td>
                                                                <th scope="row" className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                    {device.deviceCode}
                                                                </th>
                                                                <td className="px-6 py-4">
                                                                    {device.ipAddress}
                                                                </td>
                                                                <td className="px-6 py-4">
                                                                    {device.resolution}
                                                                </td>
                                                                <td className="px-6 py-4">
                                                                    {device.monitorSize}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4" aria-label="Table navigation">
                                                    <span className="text-xs font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing <span className="font-semibold text-gray-900 dark:text-white">1-4</span> of <span className="font-semibold text-gray-900 dark:text-white">100</span></span>
                                                    <ul className="inline-flex -space-x-px rtl:space-x-reverse text-xs h-8">
                                                        <li>
                                                            <a href="#" className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
                                                        </li>
                                                        <li>
                                                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
                                                        </li>
                                                        <li>
                                                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">2</a>
                                                        </li>
                                                        <li>
                                                            <a href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">3</a>
                                                        </li>
                                                        <li>
                                                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">4</a>
                                                        </li>
                                                        <li>
                                                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">5</a>
                                                        </li>
                                                        <li>
                                                            <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="bg-gray-200 rounded-md h-80">
                                            <p className="p-2 px-4 font-semibold text-md">Sequence Preview</p>
                                            {checkedCount === 0 ? (<div className="flex flex-col justify-center items-center h-64">
                                                <icons.PI.PiWarningCircleDuotone size={30} />
                                                <div className="mt-2">No Device Selected
                                                </div>
                                            </div>) : (
                                                <div className="flex justify-center items-center p-4">
                                                    {Array.from({ length: checkedCount }).map((_, index) => (
                                                        <icons.MD.MdMonitor key={index} className="text-gray-600" size={83}/>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-col-span-1">
                                    <div className="grid grid-cols-5 gap-4">

                                        <div className="bg-white rounded-lg shadow-md p-2 col-span-2">
                                            <div className="flex text-sm justify-between mb-1 p-2">
                                                <div className="flex flex-col items-start">
                                                    <div className="inline-flex items-center">
                                                        <input id="country-option-1" type="radio" name="countries" value="USA" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" />
                                                        <label htmlFor="country-option-1" className="block ms-2  text-sm font-medium text-gray-900 dark:text-gray-300">
                                                            Bank of Monitor
                                                        </label>
                                                    </div>
                                                    <span className="text-xs text-gray-500 ml-7">1 or more devices</span>
                                                </div>

                                                <div className="flex flex-col items-start">
                                                    <div className="inline-flex items-center">
                                                        <input id="country-option-1" type="radio" name="countries" value="USA" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" />
                                                        <label htmlFor="country-option-1" className="block ms-2  text-sm font-medium text-gray-900 dark:text-gray-300">
                                                            Dedicated
                                                        </label>
                                                    </div>
                                                    <span className="text-xs text-gray-500 ml-7">Single Device</span>
                                                </div>
                                            </div>

                                            <div className="flex items-center">
                                                <button
                                                    type="button"
                                                    className="flex items-center cursor-pointer transition-colors duration-300 rounded-full rounded-3xl text-sm px-1 py-1"
                                                    onClick={() => { setIsActive(prev => !prev); }}
                                                >
                                                    {isActive ? (
                                                        <icons.BS.BsToggleOn className="text-green-500" size={30} />
                                                    ) : (
                                                        <icons.BS.BsToggleOff className="text-gray-500" size={30} />
                                                    )}
                                                </button>
                                                <span className="text-xs text-gray-400 flex flex-col pl-2">
                                                    <div>Enable Page File Grouping</div>
                                                    <small className="font-light">Recuring Template Over Bank</small>
                                                </span>
                                            </div>
                                        </div>

                                        <div className={`rounded-lg p-4 ${isActive ? 'bg-white shadow-md' : 'bg-gray-200'}`}>
                                            <label htmlFor="template_grouping" className="text-xs font-medium text-gray-900 dark:text-white mr-2">Template Grouping :</label>
                                            <select
                                                id="template_group"
                                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5`}
                                                disabled={!isActive}
                                            >
                                                <option selected disabled>Select-Template</option>
                                                <option>Departure_T1</option>
                                                <option>Gate_Uni</option>
                                                <option>Arrival</option>
                                                <option>Latest_Promo</option>
                                            </select>
                                        </div>

                                        <div className="bg-white rounded-lg shadow-md p-2 col-span-2">
                                            <div className="grid grid-cols-[80px_0.5fr_0.5fr_0.8fr] gap-4">
                                                <div className="flex items-center">
                                                    <button
                                                        type="button"
                                                        className="flex items-center cursor-pointer transition-colors duration-300 rounded-full rounded-3xl text-sm px-1 py-1"
                                                        onClick={() => { setSecondPage(prev => !prev); }}
                                                    >
                                                        {isSecondPage ? (
                                                            <icons.BS.BsToggleOn className="text-green-500" size={30} />
                                                        ) : (
                                                            <icons.BS.BsToggleOff className="text-gray-500" size={30} />
                                                        )}
                                                    </button>
                                                    <span className="text-xs text-gray-400 pl-2">
                                                        2nd Page
                                                    </span>
                                                </div>
                                                <div>
                                                    <label htmlFor="small-input" className="text-xs font-medium text-gray-900 dark:text-white mr-2">1st interval</label>
                                                    <input type="number" id="small-input" placeholder="60s" className="block w-full p-1 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                </div>
                                                <div>
                                                    <label htmlFor="small-input" className="text-xs font-medium text-gray-900 dark:text-white mr-2">2nd interval</label>
                                                    <input type="number" id="small-input" placeholder="60s" className="block w-full p-1 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                </div>
                                                <div>
                                                    <label htmlFor="small-input" className="text-xs font-medium text-gray-900 dark:text-white mr-2">Transition</label>
                                                    <select id="template_group" className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                        <option disabled selected>--Transition</option>
                                                        <option>Top-Bottom</option>
                                                        <option>Bottom-Top</option>
                                                        <option>Flip</option>
                                                        <option>Blink</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center pt-2">
                                                <p className="text-sm font-normal text-gray-600">Template Interval :</p>
                                                <div>
                                                    <form className="max-w-sm mx-auto">
                                                        <select id="template_group" className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                            <option disabled selected>--Template</option>
                                                            <option>Air Asia BIG Offer !</option>
                                                            <option>Gate_Uni</option>
                                                            <option>Arrival</option>
                                                            <option>Latest_Promo</option>
                                                        </select>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button
                                data-modal-hide="static-modal"
                                type="button"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Save
                            </button>
                            <button
                                onClick={onClose}
                                data-modal-hide="static-modal"
                                type="button"
                                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddDeviceCluster;
