import React, { useState } from "react";
import { icons } from "../../../utils/icons";
import NewClusterLocation from "./newClusterLocation";
import Confirmation from "../../../components/shared/confirmationDialog";

interface NewClusterModalProp {
    onClose: () => void;
}

const NewClusterModal: React.FC<NewClusterModalProp> = ({ onClose }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isSpecificTerminal, setIsSpecificTerminal] = useState<boolean>(false)
    const [selectedTerminal, setSelectedTerminal] = useState<string>('');
    const [onConfirm, setOnConfirm] = useState<boolean>(false)

    const [isSpecificAirline, setIsSpecificAirline] = useState<boolean>(false)
    const [selectedAirline, setSelectedAirline] = useState<string>('');

    const onSave = () => {
        return (<>hello</>)
    }

    const handleCheckboxChange = (checkboxSetter: React.Dispatch<React.SetStateAction<boolean>>, resetSetter: React.Dispatch<React.SetStateAction<string>>) => {
        checkboxSetter(prev => {
            const newValue = !prev;
            if (!newValue) {
                resetSetter('');
            }
            return newValue;
        });
    };

    const stepContents = [
        {
            title: "Step 1: New Cluster Detail",
            content: (
                <div className="flex flex-col md:flex-row md:p-5">
                    <div className="w-full md:w-1/3 px-4 flex border-r border-gray-200 grid grid-rows-4 grid-flow-col gap-2">
                        <div className="grid grid-cols-2 items-center border-b border-gray-200">
                            <div className="font-bold">Cluster Code</div>
                            <div className="pb-5">
                                <form className="w-full">
                                    <div className="relative z-0 w-full group">
                                        <input
                                            type="text"
                                            name="floating_clusterId"
                                            id="floating_clusterId"
                                            className="block w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            placeholder=" "
                                            required
                                        />
                                        <label htmlFor="floating_clusterId" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Cluster ID</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 items-center border-b border-gray-200">
                            <div className="py-5 font-bold">Display Label Code</div>
                            <div className="py-5">
                                <form className="max-w-sm mx-auto">
                                    <select id="display_label_type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option>Option 1</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 items-center border-b border-gray-200">
                            <div className="py-5 font-bold">Channel</div>
                            <div className="py-5">
                                <form className="max-w-sm mx-auto">
                                    <select id="channel" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option>Option 1</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 items-center">
                            <div className="py-5 font-bold">Client Type</div>
                            <div className="py-5">
                                <form className="max-w-sm mx-auto">
                                    <select id="client_type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option>Option 1</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-2/3 p-4 flex">
                        <div className="grid grid-rows-4 grid-flow-col gap-2 w-full">

                            <div className="grid row-span-1 grid-cols-3 border-b gap-4 border-gray-200">
                                <div className="col-span-1">
                                    <div className="grid grid-cols-2 items-center">
                                        <div className="font-bold">Group By</div>
                                        <div>
                                            <form className="max-w-sm mx-auto">
                                                <select id="client_type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    <option>Option 1</option>
                                                    <option>Option 2</option>
                                                    <option>Option 3</option>
                                                    <option>Option 4</option>
                                                </select>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-2">
                                    <div className="grid grid-cols-8 gap-4 items-center">
                                        <div className="text-gray-400">Range</div>
                                        <div className="col-span-3">
                                            <form className="max-w-sm mx-auto">
                                                <select id="client_type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    <option>Option 1</option>
                                                    <option>Option 2</option>
                                                    <option>Option 3</option>
                                                    <option>Option 4</option>
                                                </select>
                                            </form>
                                        </div>
                                        <div className="text-center text-gray-400">to</div>
                                        <div className="col-span-3">
                                            <form className="max-w-sm mx-auto">
                                                <select id="client_type_2" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    <option>Option 1</option>
                                                    <option>Option 2</option>
                                                    <option>Option 3</option>
                                                    <option>Option 4</option>
                                                </select>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="grid row-span-3 grid-cols-2 gap-4 pt-6">
                                <div className="border-r border-gray-200">
                                    <div className="flex items-center">
                                        <input
                                            onClick={() => handleCheckboxChange(setIsSpecificTerminal, setSelectedTerminal)}
                                            checked={isSpecificTerminal}
                                            id="checkbox-1" type="checkbox"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="checkbox-1" className="ms-2 text-sm font-bold text-gray-900 dark:text-gray-300">Terminal Specific</label>
                                    </div>

                                    <form className="px-4 pt-2">
                                        <select
                                            disabled={!isSpecificTerminal}
                                            id="terminal"
                                            value={selectedTerminal}
                                            onChange={(e) => setSelectedTerminal(e.target.value)}
                                            className={`${isSpecificTerminal ? 'bg-gray-50' : 'bg-gray-300'} border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                                            <option selected>--Terminal--</option>
                                            <option>KLIA 1</option>
                                            <option>KLIA 2</option>
                                            <option>PIA</option>
                                        </select>
                                    </form>

                                    <div className="flex items-center pt-6 pl-4">
                                        <input
                                            onClick={() => handleCheckboxChange(setIsSpecificAirline, setSelectedAirline)}
                                            id="checkbox-2" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="checkbox-2" className="ms-2 text-sm font-bold text-gray-900 dark:text-gray-300">Airline Specific</label>
                                    </div>

                                    <form className="px-4 pt-2">
                                        <select
                                            disabled={!isSpecificAirline}
                                            id="airline"
                                            value={selectedAirline}
                                            onChange={(e) => setSelectedAirline(e.target.value)}
                                            className={`${isSpecificAirline ? 'bg-gray-50' : 'bg-gray-300'} border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                                            <option value="">--Airline--</option>
                                            <option value="airasia">Air Asia</option>
                                            <option value="mas">Malaysia Airline</option>
                                            <option value="batikair">Batik Air</option>
                                            <option value="malindoair">Malindo Air</option>
                                        </select>
                                    </form>

                                </div>

                                <div>
                                    <div className="flex items-center pb-2">
                                        <input id="country-option-1" type="radio" name="countries" value="USA" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" checked />
                                        <label htmlFor="country-option-1" className="block ms-2  text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Public Display
                                        </label>
                                    </div>

                                    <div className="flex items-center">
                                        <input id="country-option-2" type="radio" name="countries" value="Germany" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="country-option-2" className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Non Public Display
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "Step 2: Set Cluster Location",
            content: (
                <div className="flex flex-col md:flex-row md:p-5">
                    <NewClusterLocation />
                </div>
            ),
        },

    ];

    const handleNext = () => {
        if (currentStep < stepContents.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <div
            id="default-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-6xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <div className="flex items-center">
                            <div
                                style={{
                                    backgroundImage: `url(${require(`../../../assets/icon_1.png`)})`,
                                    height: '40px',
                                    width: '40px',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                }}
                                className="mr-2"
                            ></div>
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {stepContents[currentStep].title}
                            </h3>
                        </div>

                        <button onClick={onClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="flex justify-center w-full">
                        {stepContents[currentStep].content}
                    </div>

                    <div className="flex justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button onClick={handlePrevious} disabled={currentStep === 0} className="py-2.5 px-5 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            Previous
                        </button>
                        {currentStep !== 1 ? (<button onClick={handleNext} disabled={currentStep === stepContents.length - 1} className="py-2.5 px-5 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            Next
                        </button>) : (<button type="button" onClick={() => setOnConfirm(true)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                        )}
                    </div>
                </div>
            </div>
            <Confirmation isOpen={onConfirm} onClose={() => { setOnConfirm(false) }} title="Save New Cluster?" message="Are you sure want to save this cluster?" onConfirm={onClose} />
        </div>
    );
};

export default NewClusterModal;
