import React from "react"
import { icons } from "../../../utils/icons";

interface ClusterDeviceListTableProp {
    name: string
    deviceDetail: (device: object) => void
}

const ClusterDeviceListTable: React.FC<ClusterDeviceListTableProp> = ({ name, deviceDetail }) => {
    const deviceList = [
        {
            name: 'SmartMonitor_0001',
            clusterId: 'Cluster_0001',
            bankId: 0,
            clientId: 'Client001',
            ipAdress: '192.168.1.1',
            sequence: 1,
            deviceCode: 'Check In',
            status: 1
        },
        {
            name: 'SmartMonitor_0002',
            clusterId: 'Cluster_0001',
            bankId: 0,
            clientId: 'Client001',
            ipAdress: '192.168.1.1',
            sequence: 1,
            deviceCode: 'Gate Entry',
            status: 0
        },
        {
            name: 'Bank(4)',
            clusterId: 'Cluster_0001',
            bankId: 10201,
            clientId: 'BANK',
            ipAdress: '-',
            sequence: 0,
            deviceCode: 'Gate Entry',
            status: 1
        }
    ];

    return (
        <div className="w-full p-4">
            <div>
                <p className="border-b border-gray-400 pb-4 font-semibold text-xl">{name}</p>

                <div className="relative overflow-x-auto pt-4">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Item
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    CLuster ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Bank ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Client ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    MAC Address
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Sequence
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Device Type Code
                                </th>
                                <th scope="col" className="px-6 py-3">

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {deviceList.map((device, index) => (
                                <React.Fragment key={index}>
                                    <tr className="bg-white dark:bg-gray-800">
                                        <td className="px-6 py-2 bg-white flex items-center">
                                            <icons.GO.GoDotFill className="mr-2" style={{ color: `${device.status ? '#90ee90' : '#ee9090'}` }} />
                                            {device.name}
                                        </td>
                                        <td className="px-6">
                                            {device.clusterId}
                                        </td>
                                        <td className="px-6">
                                            {device.bankId}
                                        </td>
                                        <td className="px-6">
                                            {device.clientId}
                                        </td>
                                        <td className="px-6">
                                            {device.ipAdress}
                                        </td>
                                        <td className="px-6">
                                            {device.sequence}
                                        </td>
                                        <td className="px-6">
                                            {device.deviceCode}
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => deviceDetail(device)} // Pass the device to the parent
                                                className="p-2 rounded hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition ease-in-out duration-150"
                                                aria-label="View device details"
                                            >
                                                <icons.MD.MdOutlineSearch className="text-blue-500" />
                                            </button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default ClusterDeviceListTable