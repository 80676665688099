import { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const TopAirline = () => {
  const options = {
    series: [
      {
        name: 'Flights',
        data: ['423', '329', '224', '149', '131', '104', '98', '97', '64', '59'],
      },
    ],
    chart: {
      type: 'bar',
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: false,
      },
      height: '100%', // Ensures it fits the container height
      width: '100%', // Ensures it fits the container width
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadiusApplication: 'end',
        borderRadius: 2,
        dataLabels: {
          enabled: false, // Disable the numbers on the bars
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
    },
    dataLabels: {
      enabled: false, // Ensure data labels are disabled globally
    },
    xaxis: {
      categories: ['MH', 'AK', 'FY', 'QR', 'OD', 'TK', 'WY', 'GA', 'SQ', 'UL'],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      padding: {
        left: 10,
        right: 10,
        top: -10,
        bottom: 10,
      },
    },
  };

  useEffect(() => {
    const chart = new ApexCharts(document.getElementById('bar-chart'), options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <div className="w-full h-full bg-white rounded-lg shadow dark:bg-gray-800 md:p-4">
      <div className="flex justify-between border-gray-200 border-b dark:border-gray-700 pb-2">
        <h2 className="leading-none text-l font-bold text-gray-900 dark:text-white">
          Top 10 Airlines
        </h2>
      </div>
      <div className="flex-grow mt-0">
        <div id="bar-chart" className="w-full" style={{ height: '250px' }}>
          {/* The chart will render here */}
        </div>
      </div>
    </div>
  );
};

export default TopAirline;
