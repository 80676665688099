import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const Domvsint = () => {
  const options = {
    colors: ["#1A56DB", "#FDBA8C"],
    series: [
      {
        name: "Domestic",
        color: "#1A56DB",
        data: [
          { x: "Aug 1", y: 231 },
          { x: "Aug 2", y: 122 },
          { x: "Aug 3", y: 63 },
          { x: "Aug 4", y: 421 },
          { x: "Aug 5", y: 122 },
          { x: "Aug 6", y: 323 },
          { x: "Aug 7", y: 111 },
        ],
      },
      {
        name: "International",
        color: "#FDBA8C",
        data: [
          { x: "Aug 1", y: 232 },
          { x: "Aug 2", y: 113 },
          { x: "Aug 3", y: 341 },
          { x: "Aug 4", y: 224 },
          { x: "Aug 5", y: 522 },
          { x: "Aug 6", y: 411 },
          { x: "Aug 7", y: 243 },
        ],
      },
    ],
    chart: {
      type: 'bar',
      fontFamily: 'Inter, sans-serif',
      toolbar: { show: false },
      height: '300 px', // Set height of the chart to 150px
      width: '100%',
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
        borderRadiusApplication: 'end',
        borderRadius: 8,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: { fontFamily: 'Inter, sans-serif' },
    },
    states: {
      hover: {
        filter: { type: 'darken', value: 1 },
      },
    },
    stroke: { show: true, width: 0, colors: ['transparent'] },
    grid: {
      show: true,
      strokeDashArray: 4,
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
    },
    dataLabels: { enabled: false },
    legend: { 
      show: true,
      position: 'top', // Position legend at the top
      horizontalAlign: 'right', // Align legend to the right
      verticalAlign: 'top', // Align legend at the top
      floating: true, // Enable floating for better positioning
      offsetX: 10, // Adjust horizontal position
      offsetY: 5, // Adjust vertical position
      fontSize: '12px', // Reduced font size for legend items
      itemMargin: {
        horizontal: 5, // Space between items
        vertical: 0, // Space above/below items
      },
    },
    xaxis: {
      floating: false,
      labels: {
        show: true,
        style: {
          fontFamily: 'Inter, sans-serif',
          cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400',
        },
        rotate: -45,
      },
      axisBorder: { show: true },
      axisTicks: { show: true },
    },
    yaxis: { 
      show: true 
    },
    fill: { opacity: 1 },
  };

  useEffect(() => {
    const chart = new ApexCharts(document.getElementById('column-chart'), options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <div className="w-full h-full bg-white rounded-lg shadow dark:bg-gray-800 md:p-6">
      {/* Title Section */}
      <div className="flex justify-between pb-0 mb-0 border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-center">
          <h5 className="leading-none text-l font-bold text-gray-900 dark:text-white pb-1">
            Domestic vs International Flights
          </h5>
        </div>
      </div>

      {/* Chart Container with Fixed Height */}
      <div className="flex-grow">
        <div id="column-chart" className="w-full"></div>
      </div>
    </div>
  );
};

export default Domvsint;
