import React, { useState } from "react";
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import PreviewTemplate from "../components/previewTemplate";
import { icons } from "../../../utils/icons";
import CanvasTemplate from "../components/canvasTemplate";

export default function TemplateEditor() {
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const [isToolsList, setIsToolsList] = useState<boolean>(false);
    const [isPreview, setIsPreview] = useState<boolean>(false);

    const handle = useFullScreenHandle();

    const toggleFullScreen = () => {
        setIsFullScreen((prevState) => !prevState);
    };

    const togglePreview = () => {
        setIsPreview((prevState) => !prevState);
        if (!handle.active) {
            handle.enter();
        } else {
            handle.exit();
        }
    };

    return (
        <>
            <div className={`flex flex-col ${isFullScreen ? 'fixed top-0 left-0 h-screen w-screen z-50' : 'min-h-full'}`}> {/* Adjust here */}
                <div className="grid grid-cols-8 bg-gray-500">

                    <div className="col-span-7 flex justify-end gap-2 text-white pr-2">
                        <button onClick={togglePreview}><icons.MD.MdVisibility /></button>
                        <button onClick={toggleFullScreen}>{!isFullScreen ? <icons.MD.MdZoomOutMap /> : <icons.MD.MdZoomInMap />} </button>
                    </div>

                    <div className="col-span-1 bg-gray-500 flex flex-col items-center justify-center">
                        <div className="grid grid-cols-2 w-full max-w-xs">
                            <button
                                className="w-full flex justify-center p-1 bg-gray-500 text-white"
                                onClick={() => setIsToolsList(false)}>
                                <icons.MD.MdOutlineLayers />
                            </button>
                            <button
                                className="w-full flex justify-center p-1 bg-gray-500 text-white"
                                onClick={() => setIsToolsList(true)}>
                                <icons.MD.MdWindow />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex-1 grid grid-cols-8 text-white h-full"> {/* Ensure the grid takes full height */}
                    {/* Drawing */}
                    <div className="col-span-7 bg-gray-400 relative h-full border-r-2 border-solid border-gray-500">
                        <CanvasTemplate width={1920} height={1080} />
                    </div>

                    {/* Tools */}
                    <div className="col-span-1 bg-gray-400 flex flex-col h-full"> {/* Ensure Tools section also fills the height */}
                        <div className="flex-grow">
                            <div className="flex pt-1">
                                <div className="grid grid-cols-4 gap-2">
                                    <h1>Tools</h1>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1">
                            <button type="button" className="w-full text-white bg-blue-700 hover:bg-blue-800 items-center justify-center focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <icons.MD.MdAdd />
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <FullScreen handle={handle}>
                {isPreview && <PreviewTemplate onClose={togglePreview} />}
            </FullScreen>
        </>
    );
}
