// icons.ts
import * as MD from "react-icons/md";
import * as FA from "react-icons/fa";
import * as PI from "react-icons/pi";
import * as RI from "react-icons/ri";
import * as GO from "react-icons/go";
import * as BS from "react-icons/bs";
import * as GR from "react-icons/gr";
import * as CI from "react-icons/ci";
import * as AI from "react-icons/ai";
import * as TFI from "react-icons/tfi";
export const icons = { PI, MD, FA, RI, GO, BS, GR, CI, AI, TFI };