import { useEffect } from 'react'; 
import ApexCharts from 'apexcharts';

const AoFDC = () => {
  useEffect(() => {
    // Render Donut Chart
    const renderDonutChart = () => {
      const options = {
        series: [1951, 9, 8, 4, 6, 5],
        colors: ["#1C64F2", "#16BDCA", "#FDBA8C", "#E74694", "#570987", "#F020D8"],
        chart: {
          type: "donut",
          height: "100%",
          fontFamily: "Inter, sans-serif",
        },
        stroke: {
          colors: ["transparent"],
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: "Delayed Code",
                  formatter: function (w: { globals: { seriesTotals: any[]; }; }) {
                    const sum = w.globals.seriesTotals.reduce((a: any, b: any) => a + b, 0);
                    return `${sum}`;
                  },
                  style: {
                    fontSize: '5px',
                  },
                },
              },
            },
          },
        },
        labels: ["OPE", "WEA", "LAT", "TEC", "MLD", "FLA"],
        legend: {
          position: "left",
          fontSize: '12px',
          offsetX: -20,
        },
      };

      const chart = new ApexCharts(document.getElementById("donut-chart"), options);
      chart.render();

      return () => chart.destroy();  
    };

    // Render Area Chart
    const renderAreaChart = () => {
      const options = {
        chart: {
          type: "area",
          height: "100%",
          fontFamily: "Inter, sans-serif",
          toolbar: {
            show: false,
          },
        },
        series: [
          {
            name: "Delayed flights",
            data: [1951, 9, 8, 4, 6, 5],
            color: "#1A56DB",
          },
        ],
        xaxis: {
          categories: ['OPE', 'WEA', 'LAT', 'TEC', 'MLD', 'FLA'],
        },
      };

      const chart = new ApexCharts(document.getElementById("area-chart"), options);
      chart.render();

      return () => chart.destroy();  
    };

    
    const donutCleanup = renderDonutChart();
    const areaCleanup = renderAreaChart();

    
    return () => {
      donutCleanup();
      areaCleanup();  
    };
  }, []); 

  return (
    <div className="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-6 flex flex-col h-full">
      <div className="flex justify-between mb-10">
        <div className="flex justify-center items-center">
          <h5 className="text-l font-bold leading-none text-gray-900 dark:text-white mb-2">Delays Flight Code</h5>
        </div>
      </div>

      {/* Chart Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-[200px]">
        <div id="donut-chart" className="w-full"></div>
        <div id="area-chart" className="w-full"></div>
      </div>
    </div>
  );
};

export default AoFDC;
