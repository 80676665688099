export const resolutions = [
    "3840×2160",
    "2560×1440",
    "1920×1080",
    "1366×768",
    "1280×1024",
    "1440×900",
    "1600×900",
    "1680×1050",
    "1280×800",
    "1024×768",
]

export const monitorSize = [
    17,
    19,
    20,
    21.5,
    23,
    24,
    25,
    27,
    28,
    29,
    30,
    32,
    34,
    38,
    40,
    43,
    49
]

export const monitorBrands = [
    'Samsung',
    'Philips',
    'Dell',
    'LG',
    'HP',
    'BenQ',
    'ViewSonic',
    'AOC',
    'ACER',
    'ASUS'
]

export const shortCutList = [{
    href: '/flight-info-display/template-editor',
    title: 'Template Editor',
    definition: 'Paging',
    background: 'templateEditor.png'
},
{
    href: '/flight-info-display/template-editor',
    title: 'Flight Tracker',
    definition: 'Overview',
    background: 'templateEditor.png'
},
{
    href: '/flight-info-display/template-editor',
    title: 'DCMM',
    definition: 'Device Manager List',
    background: 'templateEditor.png'
},
{
    href: '/flight-info-display/template-editor',
    title: 'DRE',
    definition: 'Display Rules Editor',
    background: 'templateEditor.png'
}
]