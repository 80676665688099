import React, { useState } from 'react';

const Filter = () => {
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [leg, setLeg] = useState('');
  const [airOperatorCode, setAirOperatorCode] = useState('');
  const [aircraftTypeCode, setAircraftTypeCode] = useState('');

  return (
    <div className="bg-gray-200 shadow-md rounded-lg p-6 h-full" style={{ fontSize: "0.95rem" }}> {/* Increased padding and font size */}
      <h2 className="text-lg font-semibold text-black mb-5">Filters</h2> 

      
      <div className="mb-6"> 
        <label className="block font-bold text-black mb-2">Date Range:</label> 
        <div className="space-y-4"> 
          <input
            type="date"
            value={dateRange.start}
            onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
            className="rounded border border-gray-300 w-full p-2" 
          />
          <input
            type="date"
            value={dateRange.end}
            onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
            className="rounded border border-gray-300 w-full p-2"
          />
        </div>
      </div>

      {/* LEG Filter */}
      <div className="mb-6"> {/* Increased bottom margin */}
        <label className="block font-bold text-black mb-2">LEG:</label> 
        <input
          type="text"
          value={leg}
          onChange={(e) => setLeg(e.target.value)}
          placeholder="Enter LEG"
          className="rounded border border-gray-300 w-full p-2" 
        />
      </div>

      {/* Air Operator Code Filter */}
      <div className="mb-6"> {/* Increased bottom margin */}
        <label className="block font-bold text-black mb-2">Air Operator Code:</label> {/* Increased bottom margin */}
        <input
          type="text"
          value={airOperatorCode}
          onChange={(e) => setAirOperatorCode(e.target.value)}
          placeholder="Enter Air Operator Code"
          className="rounded border border-gray-300 w-full p-2" 
        />
      </div>

      {/* Aircraft Type Code Filter */}
      <div className="mb-6"> {/* Increased bottom margin */}
        <label className="block font-bold text-black mb-2">Aircraft Type Code:</label> {/* Increased bottom margin */}
        <input
          type="text"
          value={aircraftTypeCode}
          onChange={(e) => setAircraftTypeCode(e.target.value)}
          placeholder="Enter Aircraft Type Code"
          className="rounded border border-gray-300 w-full p-2" 
        />
      </div>
    </div>
  );
};

export default Filter;
