import OfflineDeviceMap from "../components/offlineDeviceMap";
import OfflineDeviceTable from "../components/offlineDeviceTable";
import OverViewAnalysis from "../components/overViewAnalysis";
import { icons } from "../../../utils/icons";
import { shortCutList } from "../../../utils/sharedConst";

const FlightInfoDisplay = () => {
    return (
        <div className="dashboard-container px-2 md:px-4">
            <h1 className="text-xl md:text-2xl font-bold mb-4 text-center md:text-left">FIDS Overview</h1>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
                
                {/* Left Section (Table and Map) */}
                <div className="flex flex-col md:col-span-2 gap-4">
                    <div className="rounded-xl hover:shadow-xl bg-gray-50 dark:bg-gray-800">
                        <OfflineDeviceTable />
                    </div>

                    <div className="flex items-center hover:shadow-xl justify-center rounded-xl bg-gray-50 dark:bg-gray-800 flex-grow">
                        <OfflineDeviceMap />
                    </div>
                </div>
                
                {/* Right Section (Overview and Shortcuts) */}
                <div className="flex flex-col md:col-span-1 gap-4">
                    
                    {/* Overview */}
                    <div className="flex items-center hover:shadow-xl justify-center rounded-xl bg-gray-50 dark:bg-gray-800 flex-grow">
                        <OverViewAnalysis />
                    </div>

                    {/* Shortcut Section */}
                    <div className="flex items-center justify-center rounded-xl dark:bg-gray-800 w-full">
                        <div className="grid grid-cols-2 gap-4 w-full">
                            {shortCutList.map((item, index) => (
                                <div key={index} className="w-full hover:shadow-lg relative">
                                    <a
                                        href={item.href}
                                        className="block w-full p-3 border border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 relative"
                                        style={{
                                            height: '80px',
                                            backgroundImage: `url(${require(`../../../assets/${item.background}`)})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    >
                                        <div className="absolute inset-0 bg-white opacity-70 rounded-lg hover:opacity-30 transition-opacity duration-200" />
                                        <p className="mb-2 text-sm md:text-lg font-bold tracking-tight text-gray-600 absolute top-8 left-2">
                                            {item.title}
                                        </p>
                                        <small className="text-xs md:text-sm tracking-tight text-gray-700 absolute top-14 left-2">
                                            {item.definition}
                                        </small>
                                        <icons.MD.MdArrowOutward className="absolute top-2 right-2 text-gray-900" />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default FlightInfoDisplay;
